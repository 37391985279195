import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { DeveloperLookups } from '../../../../assets/common-data/developer-lookups';

@Injectable({
  providedIn: 'root'
})
export class PortalThemeSharedService {
  themeInfo: any;

  newThemeInfo: any;

  constructor(@Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private developerLookups: DeveloperLookups) {
  }

  //getThemeDataForPortal(portalName: string): Promise<any> {
  //  return this.http.get<any>(this.baseUrl + 'api/Portal/GetPortalTheme/' + portalName).toPromise()
  //    .then(res => { // Success
  //      if (res) {
  //        this.themeInfo = JSON.parse(res.jsonData);

  //        if (portalName != 'admin') {
  //          if (this.themeInfo.extras.faviconImageUrl)
  //            this.setAppFavicon(this.themeInfo.extras.faviconImageUrl);

  //          //if (this.themeInfo.extras.loadingImageUrl)
  //          //  this.setLoadingImage(this.themeInfo.extras.loadingImageUrl, this.themeInfo.extras.isRotateLoadingImage);
  //        }
  //      }
  //    });
  //}

  async getThemeDataForPortal(portalName: string): Promise<any> {
    const res = await this.http.get<any>(this.baseUrl + 'api/Portal/GetPortalTheme/' + portalName).toPromise();

    if (res) {
      this.themeInfo = JSON.parse(res.jsonData);

      if (portalName != 'admin') {
        if (this.themeInfo.extras.faviconImageUrl)
          this.setAppFavicon(this.themeInfo.extras.faviconImageUrl);

        //if (this.themeInfo.extras.loadingImageUrl)
        //  this.setLoadingImage(this.themeInfo.extras.loadingImageUrl, this.themeInfo.extras.isRotateLoadingImage);
      }
    }
  }

  getDefaultThemeData(): Promise<any> {
    return this.http.get<any>(this.baseUrl + 'api/Theme/GetDefaultThemeDetails').toPromise()
      .then(res => {
        if (res) {
          this.themeInfo = JSON.parse(res.jsonData);
        }
      });
  }

  setAppFavicon(imagePath: string) {
    this.document.getElementById('appFavicon').setAttribute('href', this.developerLookups.base_url + imagePath);
  }

  //setLoadingImage(imagePath: string, isRotate: boolean) {
  //  if (this.document.getElementById('appLoadingImage')) {
  //    this.document.getElementById('appLoadingImage').setAttribute('src', this.developerLookups.base_url + imagePath);

  //    if (!isRotate)
  //      this.document.getElementById('appLoadingImage').classList.remove('hydra-loading-rotate');
  //  }
  //}

  setThemeDataByThemeId(themeId: number) {
    return this.http.get<any>(this.baseUrl + 'api/Portal/GetThemeByThemeID/' + themeId)
      .pipe(
        tap(data => {
          if (data) {
            this.themeInfo = JSON.parse(data.jsonData);
          }
        })
      )
  };

  setDefaultThemeData() {
    return this.http.get<any>(this.baseUrl + 'api/Theme/GetDefaultThemeDetails')
      .pipe(
        tap(data => {
          if (data) {
            this.themeInfo = JSON.parse(data.jsonData);
          }
        })
      )
  };

  getAllThemes() {
    return this.http.get<any>(this.baseUrl + 'api/Theme/GetThemeHeaders');
  }

  getThemeByThemeId(themeId) {
    return this.http.get<any>(this.baseUrl + 'api/Theme/GetThemeByThemeId/' + themeId);
  }
}
