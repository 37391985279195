import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let token = localStorage.getItem(sessionStorage.getItem('CurrentActivePortal') + '-bearerToken');

    if (request.url == 'https://jsonip.com/') {
      return next.handle(request);
    }

    if (token) {
      if (request.url.includes('api/Files/UploadSingleFile')
        || request.url.includes('api/Files/RemoveSingleFile')
        || request.url.includes('api/Files/UploadSingleReportFile')
        || request.url.includes('api/Auth/UploadProfilePhotoForUser')
        || request.url.includes('api/Auth/RemoveProfilePhotoForUser')
        || request.url.includes('api/Files/UploadMultipleFiles')
        || request.url.includes('api/Files/RemoveMultipleFiles')
        || request.url.includes('api/AppsConfig/UploadDocumentFiles')
        || request.url.includes('api/Files/UploadToDocumentsLibrary')
        || request.url.includes('api/Files/UploadSingleVideo')
        || request.url.includes('api/Files/RemoveSingleVideo')
        || request.url.includes('api/Security/UploadUsersImportFile')
        || request.url.includes('api/Security/RemoveUsersImportFile')
        || request.url.includes('api/Files/UploadDocumentFiles')
        || request.url.includes('api/ReportViewer/GetResource')
        || request.url.includes('api/Files/UploadMediaFiles')
        || request.url.includes('api/SuspenceAndDebtorsApp/SaveQuestionComment')
        || request.url.includes('api/SuspenceAndDebtorsApp/SaveTenantQuery')
        || request.url.includes('api/SuspenceAndDebtorsApp/SaveTenantEvidence')) {
        const newRequest = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + token)
        });

        return next.handle(newRequest);
      }
      else {
        const newRequest = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + token).set('Content-Type', 'application/json')
        });

        return next.handle(newRequest);
      }
    }
    else {
      return next.handle(request);
    }
  }
}
