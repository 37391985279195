import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalThemeSharedService } from './portal-theme-shared.service';
import { DeveloperLookups } from '../../../../assets/common-data/developer-lookups';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class PortalThemeSharedModule {
  static forRoot(): ModuleWithProviders<PortalThemeSharedModule> {
    return {
      ngModule: PortalThemeSharedModule,
      providers: [
        PortalThemeSharedService,
        DeveloperLookups
      ]
    }
  }
}
