
import {map} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { throwError as _throw ,  Observable } from 'rxjs';
import { LandingPageObject } from './landing-page.class';

@Injectable()
export class LandingPageService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getLandingPage(): Observable<LandingPageObject> {
    return this.http.get<any>(this.baseUrl + 'api/LandingPage/GetLandingPage').pipe(map(
      resp => resp ? JSON.parse(resp.landingPageJsonData) : null
    ));
  }

  checkIfPortalSubDomainNameExists(portalName: string): Promise<any> {
    return this.http.get<any>(this.baseUrl + 'api/Portal/CheckIfPortalSubDomainNameExists/' + portalName).toPromise();
  }

  getAllPortals(): Promise<any> {
    return this.http.get<any>(this.baseUrl + 'api/LandingPage/GetPortalsListForLandingPage').toPromise();
  }

  delay(seconds: number) {
    return new Promise(resolve => setTimeout(resolve, seconds * 1000));
  }
}
