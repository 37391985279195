import { Injectable } from '@angular/core'
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { LandingPageObject } from './landing-page.class';
import { LandingPageService } from './landing-page.service';

@Injectable()
export class LandingPageResolver implements Resolve<LandingPageObject>{

  constructor(private _landingPageService: LandingPageService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LandingPageObject> {

    return this._landingPageService.getLandingPage();

  }

}
