import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, ActivationStart, GuardsCheckStart } from "@angular/router"
import { SecurityService } from './_helpers/security.service';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { DeveloperLookups } from '../assets/common-data/developer-lookups';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

  timeoutCount: string = ''

  localUrl: string = '';

  isSwalAlertLoad = false;

  constructor(private router: Router,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    public intlService: IntlService,
    private securityService: SecurityService,
    private developerLookups: DeveloperLookups,
    private spinner: NgxSpinnerService) {

    //console.log('App Component Constructor');

    this.securityService.getUserIdleTimeInfo();

    this.router.events.subscribe(async (routerEvent: Event) => {

      if (routerEvent instanceof NavigationStart) {
        this.securityService.isLoading(true);
      }

      if (routerEvent instanceof NavigationEnd) {
        this.securityService.isLoading(false);
      }

      if (routerEvent instanceof NavigationCancel) {
        this.securityService.isLoading(false);
      }

      if (routerEvent instanceof GuardsCheckStart) {
        this.localUrl = routerEvent.url;
      }

      if (routerEvent instanceof ActivationStart) {
        await this.securityService.getTranslatedTextsForLang();

        if (this.securityService.userSecurityObject.portalName) {
          if (this.securityService.userSecurityObject.isAuthenticated && this.securityService.userSecurityObject.portalName == 'admin') {
            this.localUrl = this.localUrl == '/admin' ? '/admin/home' : this.localUrl;
            if (this.localUrl != '/' && !this.localUrl.startsWith('/admin/login') && this.securityService.adminTabs) {
              if (this.securityService.adminTabs.length == 0) {
                await this.securityService.getAdminTabs();
              }

              //if (!this.securityService.adminTabs?.find(t => this.localUrl.startsWith(t.tabLink))) {
              //  this.securityService.logout('', 'AppComponent-AdminTabsTabLink');
              //}
            }
          }
          else if (this.securityService.userSecurityObject.isAuthenticated) {
            if (this.securityService.userSecurityObject.countryCode) {
              await this.securityService.importLocale(this.securityService.userSecurityObject.countryCode);
              (<CldrIntlService>this.intlService).localeId = this.securityService.userSecurityObject.countryCode;
            }

            if (!this.securityService.portalMenuObject) {
              if (this.securityService.adminTabs.length == 0) {
                await this.securityService.getPortalMenuObject(this.securityService.userSecurityObject.portalName);
              }
            }
          }
        }
      }
    });

    this.securityService.isLoading(true);
  }

  async ngOnInit() {
    //Set page Title when this view is initialized
    this.titleService.setTitle('Hydra');

    this.securityService.loading.asObservable().subscribe(data => {
      if (!data) {
        this.spinner.hide();
      }
      else if (this.securityService.hydraStyles.loadingImageUrlTemplate) {
        this.spinner.show();
      }
      //if (data && this.securityService.hydraStyles.loadingImageUrlTemplate) {
      //  this.spinner.show();
      //}
      //else {
      //  this.spinner.hide();
      //}
    });

    if (!this.developerLookups.base_url) {
      await this.securityService.getFilesAbsoluteUri().then(res => { if (res) { this.developerLookups.base_url = res.path } });
    }

    if (window.location.pathname.startsWith('/admin') || window.location.pathname == '/') {
      await this.securityService.getHydraStyle();
      await this.securityService.getSecuritySettings();
    }

    // Start watching when user idle is starting.
    this.securityService.userIdle.onTimerStart().subscribe(count => {
      if (count) {
        this.securityService.idleTimeInfo.isTimerActive = true;

        this.timeoutCount = Math.floor(((this.securityService.idleTimeInfo.waitTimeToLogoutInSec - count) / 60)) + ' min ' + Math.floor((this.securityService.idleTimeInfo.waitTimeToLogoutInSec - count) % 60) + ' sec';

        if (!this.isSwalAlertLoad) {
          this.validateSession();
        }
      }
    });

    // Stop watch when time is up.
    this.securityService.userIdle.onTimeout().subscribe(() => {
      this.securityService.userIdle.stopTimer();

      this.securityService.logout('', 'AppComponent-Timeout');
    });

    window.addEventListener("load", (event) => {
      if (localStorage.getItem(sessionStorage.getItem('CurrentActivePortal') + '-bearerToken')) {
        if (sessionStorage.getItem('IsCurrentSessionRemoved') != '1') {
          let portals = JSON.parse(localStorage.getItem('ActivePortals'));

          if (portals && !portals.find(t => t == sessionStorage.getItem('CurrentActivePortal'))) {
            this.securityService.logout(document.location.href.replace(document.location.origin, ''), 'AppComponent-LoadEvent');
          }
          else {
            let portals = JSON.parse(localStorage.getItem('ActivePortals'));
            if (portals) {
              if (!portals.find(t => t == sessionStorage.getItem('CurrentActivePortal'))) {
                portals.push(sessionStorage.getItem('CurrentActivePortal'));
                localStorage.setItem('ActivePortals', JSON.stringify(portals));
              }
            }
            else {
              let portals = [];
              portals.push(sessionStorage.getItem('CurrentActivePortal'));
              localStorage.setItem('ActivePortals', JSON.stringify(portals));
            }
          }
        }
        else if (sessionStorage.getItem('IsCurrentSessionRemoved') == '1') {
          let portals = JSON.parse(localStorage.getItem('ActivePortals'));
          if (portals) {
            if (!portals.find(t => t == sessionStorage.getItem('CurrentActivePortal'))) {
              portals.push(sessionStorage.getItem('CurrentActivePortal'));
              localStorage.setItem('ActivePortals', JSON.stringify(portals));
            }
          }
          else {
            let portals = [];
            portals.push(sessionStorage.getItem('CurrentActivePortal'));
            localStorage.setItem('ActivePortals', JSON.stringify(portals));
          }
        }
      }

      sessionStorage.removeItem('IsCurrentSessionRemoved');
    });

    window.addEventListener("beforeunload", (event: any) => {

      if (localStorage.getItem(sessionStorage.getItem('CurrentActivePortal') + '-bearerToken')) {
        if (localStorage.getItem('ActivePortals')) {
          let portals = JSON.parse(localStorage.getItem('ActivePortals'));

          if (portals.find(t => t == sessionStorage.getItem('CurrentActivePortal')) && event.currentTarget.location.href.toLowerCase().indexOf('/login') == -1) {
            sessionStorage.setItem('IsCurrentSessionRemoved', '1');
            let portals = JSON.parse(localStorage.getItem('ActivePortals'));
            let index = portals.indexOf(sessionStorage.getItem('CurrentActivePortal'));
            portals.splice(index, 1);
            localStorage.setItem('ActivePortals', JSON.stringify(portals));
          }
        }
      }

    });
  }

  // alert to validate session
  validateSession() {
    let timerInterval;
    this.isSwalAlertLoad = true;

    Swal.fire({
      title: 'Auto sign out alert!',
      html: 'Your session will expire automatically in <strong></strong>.',
      showCancelButton: true,
      confirmButtonText: 'Continue session',
      cancelButtonText: 'End session now',
      reverseButtons: false,
      timer: this.securityService.idleTimeInfo.waitTimeToLogoutInSec * 1000,
      didOpen: () => {
        //Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector('strong')
        timerInterval = setInterval(() => {
          var minutes = Math.floor(Swal.getTimerLeft() / 60000);
          var seconds = (Swal.getTimerLeft() % 60000) / 1000;

          if (minutes <= 0 && seconds <= 0) {
            b.textContent = '0 min 0 sec';
          }
          else {
            b.textContent = minutes + ' min ' + seconds.toFixed(0) + ' sec';
          }
        }, 100)
      },
    }).then((data) => {
      if (data.isConfirmed) {
        this.continueSession();
        clearInterval(timerInterval);
      }
      else if (data.isDismissed) {
        //this.endSession();
        clearInterval(timerInterval);
      }
    });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  continueSession() {
    this.isSwalAlertLoad = false;

    this.securityService.idleTimeInfo.isTimerActive = false;

    this.securityService.userIdle.stopTimer();

    this.securityService.userIdle.resetTimer();

    this.securityService.userIdle.startWatching();
  }

  endSession() {
    this.isSwalAlertLoad = false;

    this.securityService.userIdle.stopTimer();

    this.securityService.logout('', 'AppComponent-endSession');
  }
}

