import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LandingPageService } from './landing-page.service';
import { LandingPageObject } from './landing-page.class';
import { ToastrService } from 'ngx-toastr';
import { DeveloperLookups } from '../../assets/common-data/developer-lookups';
import { SecurityService } from '../_helpers/security.service';

@Component({
  selector: 'landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  mainObject: any;

  subDomainName: string = '';
  listOfPortals: any[] = [];

  isLoadingComplete: boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private landingPageService: LandingPageService,
    private toastr: ToastrService,
    private developerLookups: DeveloperLookups,
    private securityService: SecurityService) { }

  async ngOnInit() {
    this.securityService.isLoading(true);

    this.mainObject = this.route.snapshot.data['landingPageResolvedData'];

    if (!this.mainObject) {
      await this.navigateToSoftwareOwnerOrPortal('admin');
      return;
    }

    await this.getAllPortals();

    if (this.listOfPortals.length == 0) {
      await this.navigateToSoftwareOwnerOrPortal('admin');
      return;
    }

    if (this.mainObject.navigationType == 'none' && this.mainObject.portalSelected) {
      await this.landingPageService.delay(this.mainObject.waitingTimeToNavigate);

      await this.navigateToSoftwareOwnerOrPortal(this.mainObject.portalSelected);
    }

    this.isLoadingComplete = true;
    this.securityService.isLoading(false);
  }

  async navigateToSoftwareOwnerOrPortal(portalName: string) {
    if (!portalName) {
      this.toastr.error('Please enter portal domain name', 'Error');

      return;
    }
    else if (portalName.toLocaleLowerCase() == 'admin') {
      this.router.navigate(['admin/login']);
    }
    else {
      let res = await this.landingPageService.checkIfPortalSubDomainNameExists(portalName);

      if (res && res.portalExists) {
        this.router.navigate([portalName.toLocaleLowerCase(), 'home']);
      }
      else {
        this.toastr.error('Portal does not exist with the entered sub domain name', 'Error');
      }
    }
  }

  async getAllPortals() {
    this.listOfPortals = await this.landingPageService.getAllPortals();

    if (this.listOfPortals.length > 0) {
      this.subDomainName = this.listOfPortals[0].value;
    }
  }

  onEnterClick() {
    if (this.subDomainName) {
      this.navigateToSoftwareOwnerOrPortal(this.subDomainName);
    }
  }
}
