import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SecurityService } from '../_helpers/security.service';
import { Observable } from 'rxjs';

@Injectable()
export class LoginAuthGuard implements CanActivate {

  constructor(private securityService: SecurityService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let role: string = next.data.role;

    return new Promise<boolean>((resolve) => {

      this.securityService.checkIfUserSessionIsActive().then((authObj) => {

        if (role == 'admin') {
          sessionStorage.setItem('CurrentActivePortal', 'admin');

          if (authObj.isAuthenticated && authObj.portalName == 'admin') {
            this.router.navigate(['admin/home']);
          }
          else {
            resolve(true);
          }
        }
        else if (role == 'user') {
          sessionStorage.setItem('CurrentActivePortal', next.params.portalname);

          if (authObj.isAuthenticated && authObj.portalName == next.params.portalname) {
            this.router.navigate([next.params.portalname]);
          }
          else {
            return this.securityService.checkIfPortalSubDomainNameExists(next.params.portalname).then((res) => {

              if (res.portalExists == true) {
                resolve(true);
              }
              else if (!res.portalExists && res.errorUrl) {
                window.location.href = res.errorUrl;

                resolve(false);
              }
              else {
                this.securityService.resetUserSecurityObject();

                this.router.navigate(['/']);

                resolve(false);
              }

            });
          }

        }
        else {
          this.securityService.resetUserSecurityObject();

          this.router.navigate(['/']);
        }

      });

    });

  }
}


