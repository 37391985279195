import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[autofocusInput]'
})
export class AutoFocusDirective implements OnInit {

  constructor(private elRef: ElementRef<HTMLInputElement>) { }

  ngOnInit() {
    this.elRef.nativeElement.focus();
  }

  //ngAfterContentChecked(): void {
  //  this.elRef.nativeElement.focus();
  //}

}
