import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable ,  throwError as throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SecurityService } from '../_helpers/security.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private securityService: SecurityService, @Inject('BASE_URL') private baseUrl: string) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {

        this.securityService.logout('','ErrorInterceptor-401');

      }
      if (err.status === 404 || err.status === 409) {

        return throwError(err);

      }

      const error = err.error.message || err.statusText;
      return throwError(error);
    }))
  }
}
