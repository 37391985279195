export class AppUser {
  userName: string = '';
  password: string = '';
  userType: string = '';
  portalName: string = '';

  operationSystem: string = '';
  operationSystemVersion: string = '';
  browser: string = '';
  browserVersion: string = '';
  device: string = '';

  ipAddress: string;

  uniqueCode: string;
}

export class AppUserAuth {
  userId: number = null;
  userName: string = '';
  firstName: string = '';
  lastName: string = '';
  phoneNumber: string = '';
  bearerToken: string = '';
  isAuthenticated: boolean = false;
  userType: string = '';
  loginType: string = '';
  imageUrl: string = '';
  portalName: string = '';
  portalId: number = null;
  countryCode: string = '';
  isPrimary: boolean = false;
}

export class AdminTabs {
  tabName: string = '';
  tabCode: string = '';
  tabLink: string = '';
}

export class TranslatedTexts {
  text: string = '';
  translatedText: string = '';
}

export class AppExternalUser {
  userName: string = '';
  provider: string = '';
  portalName: string = '';
}

export class IdleTimeInfo {
  isTimerActive: boolean = false;
  idleTimeForUserInSec: number = 600;
  waitTimeToLogoutInSec: number = 10;
}
