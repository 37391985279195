import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule, UrlSerializer } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import 'hammerjs';

import '@progress/kendo-angular-intl/locales/en/all';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './others/pageNotFound.component';
import { LandingPageComponent } from './landing-page/landing-page.component';

import { SecurityService } from './_helpers/security.service';
import { LandingPageService } from './landing-page/landing-page.service';

import { urlSerializer } from './_helpers/url-serializer';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { ErrorInterceptor } from './_interceptors/error.interceptor';
import { AuthGuard } from './_guards/auth.guard';
import { LoginAuthGuard } from './_guards/login-auth.guard';

import { LandingPageResolver } from './landing-page/landing-page-resolver.service';

import { UserIdleModule } from 'angular-user-idle';

import { DeviceDetectorService } from 'ngx-device-detector';
import { AutoFocusStyleModule } from './_directives/auto-focus.module';
import { DeveloperLookups } from '../assets/common-data/developer-lookups';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { KendoDateAdjustmentModule } from './_directives/kendo-date-adjustment.module';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { LoaderInterceptor } from './_interceptors/loader.interceptor';
import { PortalThemeSharedModule } from './portal/portal-shared/portal-theme-shared/portal-theme-shared.module';

const route: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    resolve: { landingPageResolvedData: LandingPageResolver }
  },
  {
    path: 'admin/login',
    loadChildren: () => import('./softwareOwner/auth/so-login.module').then(m => m.SoLoginModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./softwareOwner/software-owner.module').then(m => m.SoftwareOwnerModule)
  },
  {
    path: 'visitor',
    loadChildren: () => import('./visitor/visitor.module').then(m => m.VisitorModule)
  },
  {
    path: ':portalname/login',
    loadChildren: () => import('./portal/auth/portal-login/portal-login.module').then(m => m.PortalLoginPageModule)
  },
  {
    path: ':portalname/registration',
    loadChildren: () => import('./portal/auth/portal-registration/portal-registration.module').then(m => m.PortalRegistrationModule)
  },
  {
    path: ':portalname',
    loadChildren: () => import('./portal/portal-page/portal-page.module').then(m => m.PortalPageModule)
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    //RouterModule.forRoot(route, { enableTracing: true }),
    RouterModule.forRoot(route, {
      paramsInheritanceStrategy: 'always'
    }),
    PortalThemeSharedModule.forRoot(),
    DialogsModule,
    GridModule,
    ButtonsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      enableHtml: true,
      closeButton: true,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 900 (15 minutes), `timeout` is 10 (10 seconds)
    // and `ping` is 100000 (100000 seconds).
    UserIdleModule.forRoot({ idle: 600, timeout: 10, ping: 100000 }),
    DropDownsModule,

    AutoFocusStyleModule,
    KendoDateAdjustmentModule,
    DateInputsModule,
    NotificationModule
  ],
  entryComponents: [],
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LandingPageComponent,
  ],
  providers: [
    LandingPageService,
    DeviceDetectorService,
    SecurityService,
    DeveloperLookups,

    AuthGuard,
    LoginAuthGuard,
    LandingPageResolver,

    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: UrlSerializer, useClass: urlSerializer }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
