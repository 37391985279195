import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KendoDateAdjustmentDirective } from './kendo-date-adjustment.directive';

@NgModule({
  declarations: [KendoDateAdjustmentDirective],
  imports: [
    CommonModule
  ],
  exports: [CommonModule, KendoDateAdjustmentDirective]
})
export class KendoDateAdjustmentModule { }
