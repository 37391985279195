import { Component, Directive, Input, Optional } from "@angular/core";
import { DatePickerComponent } from "@progress/kendo-angular-dateinputs";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "kendo-datepicker"
})
export class KendoDateAdjustmentDirective {

  constructor(private host: DatePickerComponent, @Optional() private ngControl: NgControl) {
    this.host.valueChange.subscribe(value => {
      if (!value) {
        return;
      }

      let newDate = new Date();

      this.host.writeValue(new Date(value.setHours(newDate.getHours(), newDate.getMinutes(), newDate.getSeconds())));

      if (this.ngControl) {
        this.ngControl.control.setValue(new Date(value.setHours(newDate.getHours(), newDate.getMinutes(), newDate.getSeconds())));
      }
    });
  }

}
