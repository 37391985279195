import { Injectable, Inject } from "@angular/core";
import { Response } from "@angular/http";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";

import { BehaviorSubject, throwError as _throw } from "rxjs";
import { map } from 'rxjs/operators';

import { AppUser, AppUserAuth, IdleTimeInfo, AdminTabs, TranslatedTexts } from "../_userclasses/app-user";
import { UserIdleService } from "angular-user-idle";
import { DeviceDetectorService } from "ngx-device-detector";
import { DeveloperLookups } from "../../assets/common-data/developer-lookups";

@Injectable()
export class SecurityService {
  userLoginModel: AppUser = new AppUser();

  userSecurityObject: AppUserAuth = new AppUserAuth();
  idleTimeInfo: IdleTimeInfo = new IdleTimeInfo();

  isMainLoading: boolean = false;
  public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading(state: boolean, mainLoading: boolean = false) {
    //console.log('mainVar:', this.isMainLoading, ' state:', state, ' mainLoading:', mainLoading);

    if (state && mainLoading) {
      this.isMainLoading = true;
      this.loading.next(true);
    }
    else if (!state && mainLoading) {
      this.isMainLoading = false;
      this.loading.next(false);
    }
    else if (state && !mainLoading) {
      if (this.isMainLoading) {
        return;
      }
      else {
        this.loading.next(true);
      }
    }
    else {
      if (this.isMainLoading) {
        return;
      }
      else {
        this.loading.next(false);
      }
    }
  }

  adminTabs: AdminTabs[] = [];
  portalMenuObject: any = null;

  translatedTexts: TranslatedTexts[] = [];

  languageCode: string = null;
  public securityPasswordSettings = [];

  hydraStyles: { faviconImageUrl: string, loadingImageUrl: string, isRotateLoadingImage: boolean, loadingImageUrlTemplate: string, loadingBackgroundColor: string, loadingText: string }
    = { faviconImageUrl: '', loadingImageUrl: '', isRotateLoadingImage: false, loadingImageUrlTemplate: '', loadingBackgroundColor: '', loadingText: '' };
  strSplit: string[];
  locale: string;
  countryList: any;


  constructor(@Inject(DOCUMENT) private document: Document,
    @Inject('BASE_URL') private baseUrl: string,
    private http: HttpClient,
    private router: Router,
    private deviceService: DeviceDetectorService,
    public userIdle: UserIdleService,
    private developerLookups: DeveloperLookups) {
    //console.log('Security Service Initialized');
  }

  validateUser(model: any): any {
    return this.http.post<AppUserAuth>(this.baseUrl + 'api/Auth/ValidateUser', model);
  }

  login(entity: AppUser, isExternal: boolean) {
    this.resetUserSecurityObject();

    return this.http.post<AppUserAuth>(this.baseUrl + 'api/Auth/' + (isExternal ? 'LoginExternalUser' : 'LoginUser'), entity).toPromise()
      .then(result => {
        (<any>Object).assign(this.userSecurityObject, result);

        //Start watching for user inactivity.
        this.userIdle.startWatching();

        let portals = JSON.parse(localStorage.getItem('ActivePortals'));
        if (portals) {
          if (!portals.find(t => t == sessionStorage.getItem('CurrentActivePortal'))) {
            portals.push(sessionStorage.getItem('CurrentActivePortal'));
            localStorage.setItem('ActivePortals', JSON.stringify(portals));
          }
        }
        else {
          let portals = [];
          portals.push(sessionStorage.getItem('CurrentActivePortal'));
          localStorage.setItem('ActivePortals', JSON.stringify(portals));
        }

        localStorage.setItem(this.userSecurityObject.portalName + '-bearerToken', this.userSecurityObject.bearerToken);
      });
  }

  logout(redirectUrl: string, loggedOutFrom: string): void {
    console.log('User Logged Out', loggedOutFrom);

    if (this.userSecurityObject.userId) {
      this.http.get<any>(this.baseUrl + 'api/Auth/LogOutUser/' + this.userSecurityObject.userId).subscribe(
        (data) => {
          this.resetUserSecurityObject();

          if (redirectUrl) {
            this.router.navigate([sessionStorage.getItem('CurrentActivePortal'), 'login'], { queryParams: { returnurl: redirectUrl } });
          }
          else {
            this.router.navigate([sessionStorage.getItem('CurrentActivePortal'), 'login']);
          }
        },
        (error: Response) => {
          console.log(error);
        }
      );
    }
    else {
      this.resetUserSecurityObject();

      if (redirectUrl) {
        this.router.navigate([sessionStorage.getItem('CurrentActivePortal'), 'login'], { queryParams: { returnurl: redirectUrl } });
      }
      else {
        this.router.navigate([sessionStorage.getItem('CurrentActivePortal'), 'login']);
      }
    }
  }

  resetUserSecurityObject(): void {
    if (this.userSecurityObject.portalName) {
      localStorage.removeItem(this.userSecurityObject.portalName + '-bearerToken');
    }
    else if (sessionStorage.getItem('CurrentActivePortal')) {
      localStorage.removeItem(sessionStorage.getItem('CurrentActivePortal') + '-bearerToken');
    }

    this.userSecurityObject.userId = null;
    this.userSecurityObject.userName = '';
    this.userSecurityObject.firstName = '';
    this.userSecurityObject.lastName = '';
    this.userSecurityObject.phoneNumber = '';
    this.userSecurityObject.bearerToken = '';
    this.userSecurityObject.isAuthenticated = false;
    this.userSecurityObject.userType = '';
    this.userSecurityObject.loginType = '';
    this.userSecurityObject.imageUrl = '';
    this.userSecurityObject.portalName = '';
    this.userSecurityObject.countryCode = '';

    this.adminTabs = [];

    //Stop watching for user inactivity.
    this.userIdle.stopWatching();
    this.idleTimeInfo.isTimerActive = false;
  }

  async loginExternally(via: string, configId: number, redirectUrl: string) {
    let res = await this.http.get<any>(this.baseUrl + 'api/Auth/SetAuthProvider/' + configId).toPromise();

    if (res) {
      let reg = redirectUrl.split('?').join('_ques_');
      reg = reg.split('&').join('_amp_');

      this.document.location.href = this.baseUrl + 'api/Auth/SignInWithExternalProvider?provider=' + via
        + '&portalName=' + this.userLoginModel.portalName + '&redirectUrl=' + (redirectUrl ? reg : '');
    }
  }

  checkIfUserSessionIsActive(): Promise<{ isAuthenticated: boolean, portalName: string }> {
    return this.http.get(this.baseUrl + 'api/Auth/CheckSessionAndGetUserToken')
      .pipe(map((response: Response) => {
        (<any>Object).assign(this.userSecurityObject, response);

        return {
          isAuthenticated: this.userSecurityObject.isAuthenticated,
          portalName: this.userSecurityObject.portalName
        };
      })).toPromise();
  }

  getIpAddress() {
    return this.http.get<{ ip: string }>('https://jsonip.com/');
  }

  getSOLoginAuthTypes() {
    return this.http.get<any>(this.baseUrl + 'api/Auth/GetSOLoginAuths').toPromise();
  }

  getForgotPasswordText() {
    return this.http.get<any>(this.baseUrl + 'api/Auth/GetForgotPasswordText');
  }

  async checkIfPortalSubDomainNameExists(portalName: string) {
    return await this.http.get<any>(this.baseUrl + 'api/Portal/CheckIfPortalSubDomainNameExists/' + portalName).toPromise();
  }

  sendPasswordResetEmail(model: any) {
    return this.http.post<any>(this.baseUrl + 'api/Auth/SendPasswordResetEmail', model);
  }

  resetUserPassword(model: any) {
    return this.http.post<any>(this.baseUrl + 'api/Auth/ResetUserPasswordByUniqueCode', model);
  }

  checkForPasswordReset(uniqueCode: string): any {
    return this.http.get<any>(this.baseUrl + 'api/Auth/CheckForPasswordReset/' + uniqueCode).toPromise();
  }

  forceResetUserPassword(model: any) {
    return this.http.post<any>(this.baseUrl + 'api/Auth/ForceResetUserPasswordByUsername', model);
  }

  resetLoggedInUserPassword(model: any) {
    return this.http
      .post<any>(this.baseUrl + 'api/Auth/ResetLoggedInUserPassword', model);
  }

  async setUserLoginModel(userType: string, portalName: string) {
    let clientInfo = this.deviceService.getDeviceInfo();

    await this.getIpAddress()
      .subscribe(data => {
        this.userLoginModel.ipAddress = data.ip;
      });

    this.userLoginModel.userType = userType;
    this.userLoginModel.portalName = portalName;

    this.userLoginModel.operationSystem = clientInfo.os;
    this.userLoginModel.operationSystemVersion = clientInfo.os_version;
    this.userLoginModel.browser = clientInfo.browser;
    this.userLoginModel.browserVersion = clientInfo.browser_version;

    if (this.deviceService.isMobile())
      this.userLoginModel.device = 'Mobile';
    else if (this.deviceService.isTablet())
      this.userLoginModel.device = 'Tablet';
    else
      this.userLoginModel.device = 'Desktop';
  }

  getUserIdleTimeInfo() {
    return new Promise<void>((resolve, reject) => {
      this.http.get<IdleTimeInfo>(this.baseUrl + 'api/Auth/GetIdleTimeConfig').toPromise()
        .then(res => { // Success
          this.idleTimeInfo = res;

          this.userIdle.setConfigValues(
            {
              idle: res.idleTimeForUserInSec,
              timeout: res.waitTimeToLogoutInSec,
              ping: 100000
            });

          resolve();
        });
    });
  }

  getAdminTabs() {
    return new Promise<void>((resolve, reject) => {
      this.http.get<AdminTabs[]>(this.baseUrl + 'api/Auth/GetAdminTabs').toPromise()
        .then(res => { // Success
          this.adminTabs = res;
          resolve();
        });
    });
  }

  getPortalMenuObject(portalName: string) {
    return new Promise<void>((resolve, reject) => {
      this.http.get<any>(this.baseUrl + 'api/Portal/GetPortalMenuBySubDomainName/' + portalName).toPromise()
        .then(res => { // Success
          this.portalMenuObject = res;
          resolve();
        });
    });
  }

  getHydraStyle(): Promise<any> {
    return this.http.get<any>(this.baseUrl + 'api/Configuration/GetHydraStyles').toPromise()
      .then(response => { // Success
        this.hydraStyles.faviconImageUrl = response.faviconImageUrl;
        this.hydraStyles.loadingBackgroundColor = response.loadingBackgroundColor;
        this.hydraStyles.loadingText = response.loadingText;

        if (this.hydraStyles.faviconImageUrl) {
          this.document.getElementById('appFavicon').setAttribute('href', this.developerLookups.base_url + this.hydraStyles.faviconImageUrl);
        }

        this.setLoadingImageTemplate(response.loadingImageUrl, response.isRotateLoadingImage, response.loadingImageSize);
      });
  }

  setLoadingImageTemplate(loadingImageUrl, isRotateLoadingImage, loadingImageSize) {
    if (loadingImageUrl) {
      let imgStyle = "";

      switch (loadingImageSize) {
        case "Original":
          imgStyle = "";
          break;
        case "Small":
          imgStyle = "style='width:auto;height:3rem !important'";
          break;
        case "Medium":
          imgStyle = "style='width:auto;height:6rem !important'";
          break;
        case "Large":
          imgStyle = "style='width:auto;height:12rem !important'";
          break;
        case "Portal":
          this.hydraStyles.loadingBackgroundColor = 'rgba(85, 85, 85, 0.57)';
          imgStyle = "style='width:auto;height:3rem !important'";
          break;
      }

      if (JSON.parse(isRotateLoadingImage)) {
        this.hydraStyles.loadingImageUrlTemplate = "<img class='hydra-loading-rotate' src='" + this.developerLookups.base_url + loadingImageUrl + "' " + imgStyle + " />";
      }
      else {
        this.hydraStyles.loadingImageUrlTemplate = "<img src='" + this.developerLookups.base_url + loadingImageUrl + "' " + imgStyle + " />";
      }
    }
    else {
      this.hydraStyles.loadingImageUrlTemplate = '';
    }
  }

  updateProfileImage(imageModel: any): any {
    return this.http
      .post<any>(this.baseUrl + 'api/Security/UpdateUserImage', imageModel);
  }

  getCountryList() {
    return this.http.get<any>(this.baseUrl + 'api/Common/GetCountryList');
  }

  getTranslatedTextsForLang() {
    let langCode = this.userSecurityObject.countryCode ? this.userSecurityObject.countryCode : window.navigator.language;

    if (langCode) {
      return this.http.get<any>(this.baseUrl + 'api/Auth/GetTranslatedTexts/' + langCode).toPromise()
        .then(data => {
          this.translatedTexts = data;
        });
    }
    else {
      return [];
    }
  }

  updateUserCountryCode(usermodel: any): any {
    return this.http
      .post<any>(this.baseUrl + 'api/Auth/UpdateUserCountryCode', usermodel);
  }

  importLocale(languageCode: string) {
    return new Promise<void>((resolve, reject) => {
      import('@progress/kendo-angular-intl/locales/' + languageCode + '/all');
      resolve();
    });
  }

  async getFilesAbsoluteUri() {
    return this.http
      .get<any>(this.baseUrl + 'api/Common/GetFilesAbsoluteUri').toPromise();
  }

  setUpExternalAuth(portalId: any): Promise<any> {
    return this.http.get<any>(this.baseUrl + 'api/Common/SetUpExternalAuth/' + portalId).toPromise();
  }

  getSecuritySettings(): Promise<any> {
    var category = "Security Settings";
    return this.http.get<any>(this.baseUrl + 'api/DataConfig/GetConfigurationDetailsByCategory/' + category).toPromise()
      .then(response => { // Success
        this.securityPasswordSettings = response;
      });
  }

  validateResetPassword(password) {
    var message = '';
    if (this.securityPasswordSettings.length != 0)
      var isDigits = this.securityPasswordSettings.some(i => i.name == 'RequireDigit') ? this.securityPasswordSettings.find(i => i.name == 'RequireDigit').value : false;
    var isNonAlphaNumeric = this.securityPasswordSettings.some(i => i.name == 'RequireNonAlphanumeric') ? this.securityPasswordSettings.find(i => i.name == 'RequireNonAlphanumeric').value : false;
    var isUppercase = this.securityPasswordSettings.some(i => i.name == 'RequireUppercase') ? this.securityPasswordSettings.find(i => i.name == 'RequireUppercase').value : false;
    var isLowerCase = this.securityPasswordSettings.find(i => i.name == 'RequireLowercase') ? this.securityPasswordSettings.find(i => i.name == 'RequireLowercase').value : false;
    //var reqLength = this.securityPasswordSettings.some(i => i.name == "RequiredLength") ? this.securityPasswordSettings.find(i => i.name == "RequiredLength").value : 6;

    if (isDigits) {
      if (password.match(/\d+/g) == null) {
        message = 'Password should have at least one numeric value';
        return message;
      }
    }

    if (isNonAlphaNumeric) {
      if (password.match(/[^\w]|_/) == null) {
        message = 'Password should have at least one non alpha numeric value';
        return message;
      }
    }

    if (isUppercase) {
      if (password.match(/[A-Z]/) == null) {
        message = 'Password should have at least one uppercase';
        return message;
      }
    }

    if (isLowerCase) {
      if (password.match(/[a-z]/) == null) {
        message = 'Password should have at least one lowercase';
        return message;
      }
    }
    return message;
  }

  sendOTP(userId, portalName): Promise<any> {
    const params = new HttpParams()
      .set('userId', userId)
      .set('portalName', portalName);
    return this.http.get<any>(this.baseUrl + 'api/Auth/SendOTPEmail/', { params }).toPromise();
  }

  loginWithOTP(otpLoginModel): Promise<any> {
    return this.http
      .post<any>(this.baseUrl + 'api/Auth/CheckIfOtpIsValid', otpLoginModel).toPromise()
      .then(result => {
        if (!result.isRequirePasswordCreation) {
          (<any>Object).assign(this.userSecurityObject, result.userSecurityObject);

          //Start watching for user inactivity.
          this.userIdle.startWatching();

          let portals = JSON.parse(localStorage.getItem('ActivePortals'));
          if (portals) {
            if (!portals.find(t => t == sessionStorage.getItem('CurrentActivePortal'))) {
              portals.push(sessionStorage.getItem('CurrentActivePortal'));
              localStorage.setItem('ActivePortals', JSON.stringify(portals));
            }
          }
          else {
            let portals = [];
            portals.push(sessionStorage.getItem('CurrentActivePortal'));
            localStorage.setItem('ActivePortals', JSON.stringify(portals));
          }

          localStorage.setItem(this.userSecurityObject.portalName + '-bearerToken', this.userSecurityObject.bearerToken);          
        }
        return { isRequirePasswordCreation: result.isRequirePasswordCreation }
      });
  }

  getUserNamebyUserId(userId): Promise<any> {
    return this.http.get<any>(this.baseUrl + 'api/Common/GetUserNameById/' + userId).toPromise();
  }

  getOtpVerifyPageConfiguration(portalName): Promise<any> {
    return this.http.get<any>(this.baseUrl + 'api/Common/GetOtpVerifyPageConfigurationForPortal/' + portalName).toPromise();
  }

  requestEmailChange(changeEmailModel): Promise<any> {
    return this.http
      .post<any>(this.baseUrl + 'api/Auth/RequestEmailChange', changeEmailModel).toPromise();
  }
}
