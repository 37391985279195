import { DefaultUrlSerializer, UrlTree } from '@angular/router';

export class urlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    if (url.includes('SignInWithExternalProvider') || url.includes('ErrorCode'))
      return super.parse(url);
    else
      return super.parse(url.toLowerCase());
  }
}
