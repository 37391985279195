import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from "rxjs/operators";
import { SecurityService } from '../_helpers/security.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private securityService: SecurityService, @Inject('BASE_URL') private baseUrl: string) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.securityService.isLoading(true);
    return next.handle(req).pipe(
      finalize(() => this.securityService.isLoading(false))
    );
  }
}
