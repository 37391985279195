import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SecurityService } from '../_helpers/security.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private securityService: SecurityService, private http: HttpClient, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let role: string = next.data.role;

    this.securityService.userIdle.startWatching();

    if (role == 'admin') {
      sessionStorage.setItem('CurrentActivePortal', 'admin');

      if (localStorage.getItem('admin' + '-bearerToken')) {
        return new Promise<boolean>((resolve) => {

          this.securityService.checkIfUserSessionIsActive().then((authObj) => {
            if (authObj.isAuthenticated && authObj.portalName == 'admin')
              resolve(true);
            else {
              this.securityService.logout(state.url.toLowerCase(),'AuthGuard-admin');

              this.router.navigate(['admin', 'login'], { queryParams: { returnurl: state.url.toLowerCase() } });
            }

          });

        });
      }
      else {
        this.router.navigate(['admin', 'login'], { queryParams: { returnurl: state.url.toLowerCase() } });
      }
    }

    else if (role == 'user') {
      sessionStorage.setItem('CurrentActivePortal', next.params.portalname);

      if (localStorage.getItem(next.params.portalname + '-bearerToken')) {

        return new Promise<boolean>((resolve) => {

          this.securityService.checkIfUserSessionIsActive().then((authObj) => {

            if (next.params.portalname === undefined) {
              if (authObj.isAuthenticated && authObj.portalName == next.params.portalname) {
                resolve(true);
              }
              else if (!authObj.isAuthenticated && next.params.portalname) {
                this.securityService.logout(state.url.toLowerCase(), 'AuthGuard-user-portalname-undefined');

                this.router.navigate([next.params.portalname, 'login'], { queryParams: { returnurl: state.url.toLowerCase() } });
              }
              else {
                this.router.navigate(['/'], { queryParams: { returnurl: state.url.toLowerCase() } });
              }
            }
            else {
              if (authObj.isAuthenticated && authObj.portalName == next.params.portalname) {
                resolve(true);
              }
              else {
                this.securityService.logout(state.url.toLowerCase(), 'AuthGuard-user');

                this.securityService.checkIfPortalSubDomainNameExists(next.params.portalname).then((res) => {

                  if (res.portalExists == true) {
                    //sessionStorage.setItem('GlobalPortalName', next.params.portalname);

                    this.router.navigate([next.params.portalname, 'login'], { queryParams: { returnurl: state.url.toLowerCase() } });
                    resolve(false);
                  }
                  else if (!res.portalExists && res.errorUrl) {
                    window.location.href = res.errorUrl;

                    resolve(false);
                  }
                  else {
                    resolve(false);
                  }

                });

              }
            }

          });

        });

      }
      else if (next.params.portalname) {
        this.router.navigate([next.params.portalname, 'login'], { queryParams: { returnurl: state.url.toLowerCase() } });
      }
      else if (next.params.portalname) {
        return new Promise<boolean>((resolve) => {

          this.securityService.checkIfPortalSubDomainNameExists(next.params.portalname)
            .then((res) => {

              if (res.portalExists == true) {
                //sessionStorage.setItem('GlobalPortalName', next.params.portalname);

                this.router.navigate([next.params.portalname, 'login'], { queryParams: { returnurl: state.url.toLowerCase() } });

                resolve(false);
              }
              else if (!res.portalExists && res.errorUrl) {
                window.location.href = res.errorUrl;

                resolve(false);
              }
              else {
                this.router.navigate(['/'], { queryParams: { returnurl: state.url.toLowerCase() } });
              }

            });

        });
      }
      else {
        this.router.navigate(['/'], { queryParams: { returnurl: state.url.toLowerCase() } });
      }
    }
  }
}
